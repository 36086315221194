.dashboard-detail {
    .dashboard-detail-content {
        > .filter {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            position: relative;
            background-color: transparent !important;

            > .device-filter-content {
                position: absolute;
                top: calc(100%);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                margin-left: 30px;
                background-color: white;
                padding: 20px;
                z-index: 9;
                border-radius: 10px;
                > .device-filter-item {
                    margin-right: 25px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    > .checkbox {
                        margin-right: 5px;
                    }
                    > p {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--background);
                    }
                }
            }
        }

        > .device-information {
            display: flex;
            flex-direction: row;
            margin-top: 60px;
            .device-infor {
                width: 400px;
                > .content {
                    background-color: var(--gray3-color);
                    border-radius: 22px;
                    padding: 30px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > .dv-mode-option {
                        > button {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 24px;
                            text-align: center;
                            color: var(--bold-green-color);
                            border: unset !important;
                            outline: unset !important;
                            background-color: transparent;
                            cursor: pointer;
                            &:focus {
                                outline: unset !important;
                                border: unset !important;
                                box-shadow: unset !important;
                            }
                        }
                    }
                }
                > .adding-user-to-device {
                    display: flex;
                    flex-direction: column;
                    align-items: unset;
                    margin-top: 60px;
                    margin-bottom: 40px;
                    > .title {
                        font-size: 18px;
                        color: var(--bold-green-color);
                        font-weight: 700;
                        line-height: 24px;
                    }
                    > .valid-message {
                        color: red;
                        margin-top: 5px;
                        font-size: 14px;
                    }
                    .form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: 15px;
                        > input {
                            color: var(--background);
                            border: 2px solid var(--bold-green-color) !important;
                            border-radius: 100px;
                            width: 300px;
                            padding: 5px 15px;
                            margin-right: 20px;
                        }
                        > .submit-button {
                            background-color: var(--bold-green-color);
                            padding: 5px 15px;
                            border-radius: 100px;
                            color: white;
                            cursor: pointer;
                        }
                    }
                    > .invitations {
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        > .title {
                            margin-top: 10px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--bold-green-color);
                        }
                        > .invitation-list {
                            margin-top: 15px;
                            display: flex;
                            flex-direction: column;
                            > .invitation-item {
                                margin-bottom: 10px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 20px;
                                color: var(--bold-green-color);                    
                            }
                            > .hightlight {
                                font-weight: 550;
                            }
                        }
                    }
                }
            }

            
            > .shadow-infor {
                width: 100%;
                margin-left: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                > .shadow-tab {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    width: 100%;
                    .tab-item {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: var(--bold-green-color);
                        padding: 10px 20px;
                        cursor: pointer;
                        &:first-child {
                            margin-right: 50px;
                        }
                    }
                    .tab-item-active {
                        background-color: var(--bold-green-color);
                        color: white;
                        border-radius: 100px;
                    }
                }
                > .search {
                    background: #f0f0f0;
                    border-radius: 100px;
                    padding: 6px 8px;
                    margin-top: 25px;
                    width: 400px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    > img {
                        margin-right: 10px;
                        width: 15px;
                        height: 15px;
                    }
                    input {
                        width: 100%;
                        background: #f0f0f0;
                    }
                }

                > .item-section {
                    display: flex;
                    flex-direction: column;
                    width: 490px;
                    text-align: left;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 17px;
                    margin-top: 50px;
                    margin-bottom: 20px;
                }

                > .list-info {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 70px;
                    row-gap: 15px;
                    > .device-info-item-section {
                        display: flex;
                        flex-direction: column;
                        width: 210px;
                        > .name {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: var(--bold-green-color);
                        }
                        > .child-list {
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                            > .child-item {
                                display: flex;
                                flex-direction: row;
                                margin-top: 10px;
                                > .child-key {
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: var(--bold-green-color);
                                }
                                > .child-value {
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: var(--background);
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                    > .info-item {
                        display: flex;
                        flex-direction: row;
                        width: 210px;
                        > .name {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            color: var(--bold-green-color);
                        }
                        > .value {
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 17px;
                            color: var(--background);
                            margin-left: auto;
                        }
                    }
                }

                > .list-schedule-events {
                    display: flex;
                    flex-direction: column;
                    width: 490px;
                    > .child-list {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        background-color: #EDEDED;
                        border-radius: 10px;
                        margin-bottom: 10px;
                        > .child-item {
                            display: flex;
                            flex-direction: row;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            margin-right: 20px;
                            > .child-key {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--bold-green-color);
                                margin-left: 20px;
                            }
                            > .child-value {
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--background);
                                margin-left: auto;
                                
                                
                            }
                        }
                    }
                }

                
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .dashboard-detail .dashboard-detail-content > .device-information {
        flex-direction: column;
        > .device-infor {
            width: 100%;
        }
        > .shadow-infor {
            margin-left: unset;
        }
    }

    .dashboard-detail .dashboard-detail-content .device-information .device-infor > .adding-user-to-device {
        align-items: center;
    }
}
