@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGrotesk300.otf') format('opentype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGrotesk400.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGroteskMedium.otf') format('opentype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGrotesk600.otf') format('opentype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGrotesk700.otf') format('opentype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'grotesk';
    src: url('./static/FoundersGroteskBold.otf') format('opentype');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'pitchsan';
    src: url('./static/PitchSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}