@import '../../sass/variable.scss';

.pagination__container{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .pagination__content{
        display: flex;
        flex-direction: row;
        align-items: center;
        .pagi_btn{
            background-color: var(--background);
            width: 2rem;
            height: 2rem;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-bottom: 0
        }
        .previous{
            @extend .pagi_btn;
            &:hover{
                >img{
                    transform: scale(1.2)
                }
            }
        }
        .next{
            @extend .pagi_btn;
            transform: rotate(-180deg);
            &:hover{
                >img{
                    transform: scale(1.2)
                }
            }
        }

        .pagination__button-disable{
            opacity: .3;
        }

        .pagination__tab{
            @extend .pagi_btn;
            background-color: white;
            border: 1px solid var(--background);
            margin: 0 5px;
            &:hover{
                background-color: rgba($color: var(--background), $alpha: .3);
            }
        }

        .pagination__tab-current{
            @extend .pagination__tab;
            background-color: var(--background);
            color: white;
            border: unset;
            &:hover{
                background-color: var(--background);
            }
        }
    }
}