.dv-mode{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    >.mode-color{
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 12px;
    }
    >.power-name{
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: var(--background);
        text-transform: capitalize;
    }
    >.mode-name{
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        margin-left: 15px;
        margin-right: 15px;
        color: var(--background);
        text-transform: capitalize;
    }
}