.search-gallery-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 90px;
    row-gap: 30px;
    margin-top: 55px;

    > .gallery-view-item {
        position: relative;
        width: 100%;
        padding-top: 175%;//155
        cursor: pointer;
        .gallery-view-item-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: var(--gray3-color);
            border-radius: 13px;
            padding: 20px 15px;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            > .circle-checkbox {
                position: absolute;
                background-color: #ffffff;
                width: 20px;
                height: 20px;
                right: 10px;
                top: 10px;
                border-radius: 20px;
            }

            > .circle-checkbox-active {
                background-color: var(--bold-green-color);
                display: flex;
                justify-content: center;
                align-items: center;
                > img {
                    width: 11px;
                    height: 8px;
                }
            }

            > .device-image {
                width: 70%;
                img {
                    width: 100%;
                }
            }

            > .device-mode {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 30px;
                > .dv-mode-bg {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    border-radius: 12px;
                }
                > .dv-mode-name {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--background);
                    text-transform: capitalize;
                }
            }

            > .device-indoor-sn {
                display: flex;
                flex-direction: row;
                align-items: center;
                > p {
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--background);
                }
                > .value {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                }
                > .dv-mode-bg {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                    border-radius: 12px;
                }
            }

            > .device-info {
                display: flex;
                flex-direction: column;
                width: 100%;
                > .device-info-item {
                    display: flex;
                    flex-direction: row;
                    text-align: left;
                    margin-top: 10px;
                    display: inline-block;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--background);
                    > p {
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 22px;
                        // text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

.gallery-no-device {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1550px) {
    .search-gallery-view {
        grid-template-columns: 1fr 1fr;
        column-gap: 70px;
        > .gallery-view-item {
            padding-top: 160%;
            .gallery-view-item-content {
                > .device-info {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .search-gallery-view {
        grid-template-columns: 1fr 1fr;
        column-gap: 70px;
        > .gallery-view-item {
            padding-top: 190%;
            .gallery-view-item-content {
                > .device-info {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .search-gallery-view {
        grid-template-columns: 1fr 1fr;
        column-gap: 70px;
        > .gallery-view-item {
            padding-top: 210%;
            .gallery-view-item-content {
                > .device-info {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .search-gallery-view {
        grid-template-columns: 1fr;
        column-gap: 90px;
        > .gallery-view-item {
            padding-top: 155%;
            .gallery-view-item-content {
                > .device-info {
                    width: 80%;
                }
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .search-gallery-view {
        grid-template-columns: 1fr;
        column-gap: 90px;
        > .gallery-view-item {
            padding-top: 175%;
            .gallery-view-item-content {
                > .device-info {
                    width: 100%;
                }
            }
        }
    }
}
