.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    width: 220px;
    padding: 70px 30px;
    background: #F0F0F0;
    border-radius: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;

    >.logo{
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        >p{
            margin-top: 15px;
            color: var(--bold-green-color);
        }
    }

    >.sidebar-menu{
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin-bottom: 70px;
      
        >.sidebar-menu-item-active{
            background: var(--bold-green-color);
            border-radius: 100px;
            padding: 10px 20px;
            >p{
                color: white !important;
            }
        }
        >.sidebar-menu-item{
            margin-bottom: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
            min-height: 50px;
            cursor: pointer;
            >img{
                width: 20px;
                height: 20px;
            }
            >.data-graph{
                height: 15px;
            }
            >.zendesk{
                height: 15px;
            }
            >p{
                font-weight: 300;
                font-size: 20px;
                line-height: 24px;
                margin-left: 8px;
                color: var(--bold-green-color);
            }
        }
    }

    >.control{
        display: flex;
        // margin-top: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        position: absolute;
        bottom: 100px;
        width: calc(100% - 60px);
        justify-content: center;
        >.control-item{
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 7px;
            background-color: var(--bold-green-color);
            border-radius: 100%;
            cursor: pointer;
        }
    }
}   
@media screen and (max-width: 1280px) {
    .sidebar{
        padding: 50px 20px;
        >.control{
            bottom: 50px;
            width: calc(100% - 40px);
        }
    }
}