@import './variable.scss';
@import './fonts/fonts.css';

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--gray2-color);
    border-radius: 32px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--bold-green-color); 
    border-radius: 32px;
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: var(--font-family) !important;
    font-size: var(--normal-font-size) !important;
    background-color: var(--body-color) ;
    color: var(--text-color);
}

p{
    margin-bottom: 0 !important;
}

ul{
    list-style: none;
}
a{
    text-decoration: none;
}
img,video{
    max-width: 100%;
    height: auto;
}

input{
    border: none;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

button{
    border: none !important;
    cursor: pointer;
    outline: none !important;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

input{
    outline: none !important;
}
