.search-page{
  .search-page-content{
    >.title{
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: var(--background);
    }
    >.filter{
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      position: relative;
      background-color: transparent !important;
      >.device-search{
        background: #F0F0F0;
        border-radius: 10px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        >.search-icon{
          padding: 0 5px;
        }
        >.search-content{
          border-radius: 10px;
          background-color: white;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          >.group-input{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            >.search-item{
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 15px;
              >.text{
                font-weight: 700;
                font-size: 1rem;
                line-height: 22px;
                color: var(--background);
                margin-right: 10px;
              }
            }
          }
          >.search-btn{
            background: var(--bold-green-color);
            border-radius: 100px;
            padding: 5px;
            padding: 10px 15px;
            color: #FAF9F6;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
            display: flex;
            margin-left: auto;
          }
        }
      }
    }

    >.search-page-result{
      display: flex;
      flex-direction: column;
      margin-top: 85px;
      >.search-page-result-header{
        display: flex;
        >.title{
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          color: var(--background);
        }
        >.search-page-result-header-view{
          display: flex;
          margin-left: auto;
          flex-direction: row;
          align-items: center;
          >.dots{
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: var(--bold-green-color);
            margin: 0 20px;
          }
          >.item{
            margin-left: 25px;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: var(--background);
            cursor: pointer;
          }
          >.item-active{
            font-weight: bold;
          }
        }
      }
      >.search-page-result-content{
        >.divider{
          margin-top: 15px;
          height: 1px;
          background-color: rgba($color: #000000, $alpha: .5);
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .search-page{
    .search-page-content{
      >.filter{
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 1440px ) {
    .device-search{
        >.search-content{
            >.group-input{
                grid-template-columns:  1fr 1fr 1fr;
            }
        }
    }
}


@media screen and (max-width: 1280px ) {
    .device-search{
        >.search-content{
            >.group-input{
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

@media screen and (max-width: 900px ) {
    .device-search{
        >.search-content{
            >.group-input{
                grid-template-columns: 1fr;
            }
        }
    }
}
