.listview-table{
    .warning-icon{
        height: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        >div{
            width: 25px;
            height: 25px;
            background-color: var(--orange-color);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            >img{
                width: 15px;
                height: 15px;
            }

        }
    }
    tbody{
        margin-top: 50px;
        border-top: unset !important;
        tr {
          cursor: pointer;
          &:hover {
            background: var(--gray3-color);
          }
        }
    }
    .listview-tr{
        >th{
            height: 60px;
            padding: unset;
            >.product-image{
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                >img{
                    width: 40px;
                    height: 40px;
                }
            }
        }
        >td{
            height: 60px;
            padding: unset;
            >div{
                height: 60px;
                display: flex;
                align-items: center;
                margin-left: 5px;
                >.dots{
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    margin-right: 10px;
                }
                >b{
                    margin-right: 15px;
                }
            }
        }
    }

    .listview-header{
        padding-bottom: 10px;
        >tr{
            >th{
                padding: .5rem 0 !important;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                text-align: center;
                border-bottom: unset !important;
                box-shadow: unset !important;
            }
        }
    }
}

.listview-no-device{
    display: flex;
    justify-content: center;
    align-items: center;
}
