/*================ color ==============*/

$red-color:#FF7373;
$orange-color:#FF8C65;
$blue-color:#73CDFF;
$yellow-color:#F5F297;
$green-color:#BEEEB3;
$black-color:#192325;
$light-gray-color:#F4F4F4;
$defrost-color:#314773;
$room-line-color:#748487;
$gray-button-color:#EAEAEA;
$gray-color:#DFDFDF;
$gray2-color:#EDEDED;
$gray3-color:#F0F0F0;
$bold-green-color:#3D4C4F;
$text-green-color:#1E555C;
$background: #1E2A2C;

/*================ Font family ==============*/
$font-family: grotesk,sans-serif;

/*================ padding ===============*/
$pd-mobile: 25px;
$pd-main: 100px;

/*================ component ==============*/
$header-height: 50px;

:root {
    --red-color: #{$red-color};
    --orange-color:#{$orange-color};
    --blue-color: #{$blue-color};
    --yellow-color: #{$yellow-color};
    --green-color: #{$green-color};
    --black-color: #{$black-color};
    --light-gray-color: #{$light-gray-color};
    --defrost-color: #{$defrost-color};
    --gray-button-color: #{$gray-button-color};
    --gray-color: #{$gray-color};
    --gray2-color: #{$gray2-color};
    --gray3-color: #{$gray3-color};
    --bold-green-color: #{$bold-green-color};
    --text-green-color:#{$text-green-color};
    --background:#{$background};


    --font-family: #{$font-family};

    --header-height: #{$header-height};
}

@media (min-width: 768px){
    :root{
        --header-height: 100px;
    }
}