.layout{
    position: relative;
    margin-left: 220px;
    padding: 50px 80px;
    display: flex;
    flex-direction: column;
    >.account-information{
        display: flex;
        margin-left: auto;
        flex-direction: row;
        align-items: center;
        >.avatar{
            width: 45px;
            height: 45px;
            // border: 1px solid var(--bold-green-color);
            border-radius: 100%;
            margin-right: 10px;
            >img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }
        >.date{
            font-size: 14px;
            line-height: 17px;
            color: var(--background);
            >b{
                font-weight: bold;
            }
        }
    
        >.dots{
            margin: 0 8px;
            height: 6px;
            width: 6px;
            border-radius: 100%;
            background: var(--background);
        }
    
        >.time{
            font-size: 14px;
            line-height: 17px;
            color: var(--background);
            >b{
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .layout{
        padding: 50px ;
    }
}
@media screen and (max-width: 900px) {
    .layout{
        padding: 20px ;
    }
}