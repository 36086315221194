.nycha-page{
    .nycha-page-content{
        >.title{
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: var(--background);
        }

        >.nycha-page-result{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            >.nycha-page-result-header{
                display: flex;
                >.title{
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 38px;
                    color: var(--background);
                }
                >.nycha-page-result-header-view{
                    display: flex;
                    margin-left: auto;
                    flex-direction: row;
                    align-items: center;
                    >.dots{
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        background-color: var(--bold-green-color);
                        margin: 0 20px;
                    }
                    >.item{
                        margin-left: 25px;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--background);
                        cursor: pointer;
                    }
                    >.item-active{
                        font-weight: bold;
                    }
                }
            }
            >.nycha-page-result-content{
                >.divider{
                    margin-top: 15px;
                    height: 1px;
                    background-color: rgba($color: #000000, $alpha: .5);
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .nycha-page{
        .nycha-page-content{
            >.filter{
                flex-direction: column;
                align-items: flex-start;
                >.date-time-filter{
                    margin-top: 15px;
                    margin-left: unset;
                }
                >.device-filter{
                    margin-top: 15px;
                    margin-left: unset;
                }
            }
        }
    }
}
