.gradient-button {
  background-color: var(--bold-green-color);
  padding: 5px 15px;
  border-radius: 100px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: var(--background);
  }

}


.gradient-button.disabled {
  background-color: var(--gray-color);
  cursor: not-allowed;
  &:hover {
    background-color: var(--gray-color);
  }
}

.gradient-button.delete-button {
  background-color: var(--red-color);
  &:hover {
    background-color: var(--orange-color);
  }
}

