.dv-img{
    position: relative;
    >img{
        width: 220px;
        height: 220px;
    }
    >.temp{
        position: absolute;
        right: 5px;
        bottom: -20px;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: var(--bold-green-color);
    }
}