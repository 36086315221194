.dv-setpoint{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    >.temp{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px;
        >p{
            text-align: center;
        }
        >.temp-value{
            background-color: white;
            border-radius: 100px;
            padding: 8px 25px;
            display: flex;
            flex-direction: row;
            color: var(--background);
            >span{
                width: 6px;
                font-weight: 300;
                font-size: 9px;
                line-height: 16px;
            }
        }
        
    }
}
